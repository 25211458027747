import 'swiper/css/bundle';
import '../sass/app-nrf.scss';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';
import 'tippy.js/dist/border.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/themes/light-border.css';

import { createApp } from 'vue';
import VueTippy from 'vue-tippy';
import App from './vue/app.js';
import './components/modal';
import './components/cookie';
import './components/collapsible';
import "./components/slider";

import calculatorComponent from "./vue/components/calculatorComponent/calculatorComponent.vue";
import { store } from './vue/vueStore';

import BuildingTypeSelectorComponent from "./vue/components/buildingTypeSelectorComponent/buildingTypeSelectorComponent.vue";

import SelfScanCompanies from "./vue/components/selfScanCompanies/selfScanCompanies.vue";

//Calculator component
const vueAppFactory = () => {
    return createApp({ ...App })
        .use(store)
        .use(VueTippy, {
            flipDuration: 1000,
            defaultProps: {
                arrow: true,
                allowHTML: true,
            },
        })
        .component("calculator-component", calculatorComponent);
}

// Calculator instance for each .app-calculator target
window.addEventListener("load", function() {
    let calculatorTargets = document.querySelectorAll(".app-calculator");
    for (let calculatorTarget of calculatorTargets) {
        vueAppFactory()
            .mount(calculatorTarget);
    }

    let companyTarget = document.querySelector("#app-companies");
    if (companyTarget) {
        vueAppFactory()
            .component("self-scan-companies", SelfScanCompanies)
            .mount('#app-companies');
    }

    let nrfBuildingTypeSelectorTarget = document.querySelector(
        "#nrf-building-type-selector"
    );
    if (nrfBuildingTypeSelectorTarget) {
        vueAppFactory()
            .component("building-type-selector-component", BuildingTypeSelectorComponent)
            .mount('#nrf-building-type-selector');
    }    
});

// Start NRF tool
const checkbox = document.getElementById('check-accept-terms');
if (checkbox) {
    checkbox.addEventListener('change', acceptTerms, false);
}

function acceptTerms(){
    document.querySelector('#btn-start').disabled = !checkbox.checked;
}

// Interest checkboxes
const checkAll = document.querySelector('.check-all');
if (checkAll) {
    checkAll.addEventListener('click', checkAllBoxes);
}

const checkCat = document.querySelectorAll('.check-category');
checkCat.forEach(el => {
    el.addEventListener('click', checkCatBoxes);
})

function checkAllBoxes() {
    flipCheckBoxes(this.closest('.box-content'), this);
}

function checkCatBoxes() {
    flipCheckBoxes(this.closest('.interest-list'), this);
}

function flipCheckBoxes(container, currentCheckbox) {
    const checkboxes = container.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach(checkbox => {
        if (checkbox !== currentCheckbox) {
            checkbox.checked = currentCheckbox.checked;
        }

        expandInterest(checkbox);
    });
}

// Form elements
const inputs = document.querySelectorAll('.nrf-tool-form input');
inputs.forEach(input => {
    input.addEventListener('keyup', e => delayFormPost.bind(e)(input));
})
const radios = document.querySelectorAll('.nrf-tool-form input[type="radio"]');
radios.forEach(radio => {
    radio.addEventListener('change', () => postForm(radio));
})
const selects = document.querySelectorAll('.nrf-tool-form select');
selects.forEach(select => {
    select.addEventListener('change', () => postForm(select));
})

const consumptionSuggestions = document.querySelectorAll('.btn-consumption-calculator');
consumptionSuggestions.forEach(consumptionSuggestion => {
    consumptionSuggestion.addEventListener('click', fillEnergyConsumption);
})

const consumptionInputs = document.querySelectorAll('.nrf-tool-form input[name="energy_consumption"], .nrf-tool-form input[name="gas_consumption"]');
consumptionInputs.forEach(input => {
    input.addEventListener('keyup', resetIsSuggestion);
})

const interestCheckboxes = document.querySelectorAll('.interest-checkbox');
interestCheckboxes.forEach(checkbox => {
    expandInterest(checkbox);
    checkbox.addEventListener('change', () => expandInterest(checkbox));
})

function expandInterest(checkbox) {
    const interestContainer = checkbox.parentElement.nextElementSibling;

    if (interestContainer) {
        if (checkbox.checked) {
            interestContainer.classList.add('expanded');
        } else {
            interestContainer.classList.remove('expanded');
        }
    }
}

// Timer for saving after 1 second of not typing
let timer = null;
function delayFormPost(element) {
    if (typeof timer !== 'undefined') {
        clearTimeout(timer);
    }

    if (this.key === 'Enter') {
        postForm(element);
    } else {
        timer = setTimeout(() => {
            postForm(element);
        }, 2000);
    }
}

function postForm(element) {
    const form = element.closest('form');

    // Don't post anything in editmode
    if (form.dataset.editmode && form.dataset.editmode === "true") {
        return;
    }

    const data = new FormData(form);

    let method = 'POST';
    if (form.classList.contains('can-patch')) {
        method = 'PATCH';
    }

    postData(form.action, data, method).then((data) => {
        form.querySelectorAll('.help.is-danger').forEach(el => {
            el.parentElement.removeChild(el);
        })
        form.querySelectorAll('.is-danger').forEach(el => {
            el.classList.remove('is-danger');
        })
        form.querySelectorAll('.has-text-danger').forEach(el => {
            el.classList.remove('has-text-danger');
        })

        if (data.errors) {
            handleFormErrors(form, data.errors);
        }
    })
}

async function postData(url = '', data = {}, method = 'POST') {
    data.append('_method', method);
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: data
    });
    return response.json();
}

function handleFormErrors(form, errors) {
    Object.keys(errors).map(k => {
        const element = form.querySelector(`*[name="${k}"]`);
        if (element) {
            const helpBlock = document.createElement('p');
            helpBlock.classList.add('help', 'is-danger')
            helpBlock.innerHTML = errors[k];

            if (element.type === 'radio') {
                const isDynamicQuestion = element.classList.contains('check-answer');
                const elements = element.parentElement.parentElement.querySelectorAll('.radio');
                const parent = element.parentElement.parentElement.parentElement;

                if (isDynamicQuestion === false) {
                    elements.forEach(el => {
                        el.classList.add('has-text-danger');
                    })
                }

                const currentError = parent.querySelector('.help');
                if (currentError) {
                    parent.removeChild(currentError);
                }

                parent.appendChild(helpBlock);
            } else if (element.type !== 'hidden') {
                element.classList.add('is-danger');
                const currentError = element.parentElement.querySelector('.help');
                if (currentError) {
                    element.parentElement.removeChild(currentError);
                }
                element.parentElement.appendChild(helpBlock);
            }
        }
    })
}

function fillEnergyConsumption(event) {
    event.preventDefault();
    const element = event.target;
    const form = element.closest('form');

    const inputElement = form.querySelector(element.dataset.target);
    const isSuggestionEl = form.querySelector('input[name="consumption_is_suggestion"]');

    if (inputElement) {
        inputElement.value = inputElement.dataset.suggestion;

        if (isSuggestionEl) {
            isSuggestionEl.value = 1;
        }
    }
}

function resetIsSuggestion(event) {
    const element = event.target;

    const form = element.closest('form');
    const isSuggestionEl = form.querySelector('input[name="consumption_is_suggestion"]');

    if (isSuggestionEl && element.dataset.suggestion !== element.value) {
        isSuggestionEl.value = 0;
    } else if (isSuggestionEl && isSuggestionEl.dataset.wasSuggested === '1') {
        const suggestedFields = form.querySelectorAll('*[data-suggestion]');
        let isSuggestion = true;
        suggestedFields.forEach(field => {
            if (field.dataset.suggestion !== field.value) {
                isSuggestion = false;
            }
        });
        isSuggestionEl.value = isSuggestion ? 1 : 0;
    }
}

// Tabs
(function () {
    let tabs = document.querySelectorAll('.tabs li');
    let tabsLinks = document.querySelectorAll('.tab-content .goto-tab');
    let tabsContent = document.querySelectorAll('.tab-content');

    if (tabs.length === 0) {
        return;
    }

    let deactvateAllTabs = function () {
        tabs.forEach(function (tab) {
            tab.classList.remove('is-active');
        });
    };

    let hideTabsContent = function () {
        tabsContent.forEach(function (tabContent) {
            tabContent.classList.remove('is-active');
        });
    };

    let activateTabsContent = function (tab) {
        tabsContent[getIndex(tab)].classList.add('is-active');
    };

    let activateTab = function (tabContent) {
        tabs[getIndex(tabContent)].classList.add('is-active');
    };

    let getIndex = function (el) {
        return [...el.parentElement.children].indexOf(el);
    };

    tabs.forEach(function (tab) {
        tab.addEventListener('click', function () {
            deactvateAllTabs();
            hideTabsContent();
            tab.classList.add('is-active');
            activateTabsContent(tab);
        });
    })

    tabsLinks.forEach(function (element) {
        element.addEventListener('click', function (event) {
            event.preventDefault();
            let findElement = document.getElementById(element.dataset.id);
            if (findElement.length !== 0) {
                deactvateAllTabs();
                hideTabsContent();
                let tabContent = findElement.closest('.tab-content');
                tabContent.classList.add('is-active');
                activateTab(tabContent);
                findElement.scrollIntoView();
            }
        });
    })

    tabs[0].click();
})();

// Dynamic question elements
const answerLines = document.querySelectorAll('.check-answer');
answerLines.forEach(input => {
    input.addEventListener('change', () => checkSubQuestion(input));
})

function checkSubQuestion(el)
{
    // Reset all possible questions
    resetSubQuestions(el.dataset.id);

    // Show sub question
    let element = document.getElementById(el.dataset.id + '_' + el.dataset.position);
    if (element) {
        if (el.closest('.question-item') === undefined) {
            return;
        }

        // Overwrite possible classes (box-content, box-content-bg and is-hidden)
        let parentClass = el.closest('.question-item').classList.value;
        element.setAttribute('class', parentClass)
        hideDanger(el);
    }
}

function resetSubQuestions(parentId)
{
    const parent = parentId.split('_');
    let dataParent = parentId;
    if (parent.length > 2) {
        parent.pop();
        dataParent = parent.join('_')
    }

    const parents = document.querySelectorAll('[data-parent="' + dataParent + '"]');
    parents.forEach(el => {
        el.classList.add('is-hidden');
        el.querySelectorAll('.check-answer').forEach(input => {
            input.checked = false;
            checkSubQuestion(input)
            hideDanger(input);
        })
    })
}

function hideDanger(el)
{
    let parent = el.closest('div.control');
    parent.querySelectorAll('.is-danger').forEach(p => {
        p.style.display = 'none';
    })
}
