<template>
    <div class="self-scan-companies">
        <div class="company-intro" ref="company-intro">
            <h4 v-html="translations.introTitle"></h4>
            <div class="field is-grouped">
                <div class="control is-expanded company-intro--label">
                    <label class="label" v-html="selectedCompaniesCount"></label>
                </div>
                <div class="control company-intro--button">
                    <button
                        class="button is-primary"
                        :disabled="selectedCompanies.length <= 0"
                        v-html="translations.introButton"
                        @click="showModal('companyModal')"
                    ></button>
                </div>
            </div>
        </div>

        <div class="related-companies">
            <div v-for="company in companies"
                 :key="company.id"
                 class="related-company"
            >
                <div class="columns">
                    <div class="column related-company--info">
                        <div class="related-company--logo">
                            <div v-if="company.logo" class="related-company--logo-wrapper">
                                <img :src="company.logo" :alt="company.name"/>
                            </div>
                        </div>
                        <div class="related-company--data">
                            <h5>{{ company.name }}</h5>
                            <h6>{{ companyTypes(company) }}</h6>
                            <div v-if="company.services.length > 0"
                                 class="tags"
                            >
                                <template v-for="(service, index) in company.services">
                                    <span
                                        :class="{'tag is-white is-rounded': true, 'should-hide': index > 1, 'is-hidden': (index > 1 && !showServices.includes(company.id))}">
                                        {{ service }}
                                    </span>
                                </template>
                                <template v-if="company.services.length > 2 && !showServices.includes(company.id)">
                                    <span class="tag is-white show-more-services" @click="toggleServices(company.id)">
                                        +{{ company.services.length - 2 }}
                                    </span>
                                </template>
                            </div>

                            <div v-if="company.shortDescription !== ''"
                                 class="related-company--description"
                            >
                                <div class="related-company--description-content-wrapper" :ref="'content-wrapper-' + company.id">
                                    <div
                                        class="related-company--description-content"
                                        :ref="'content-' + company.id"
                                        v-html="company.shortDescription"></div>
                                </div>
                                <span class="related-company--description-button" @click="toggleInfo(company.id)">
                                    <span
                                        v-html="showMoreInfo.includes(company.id) ? translations.companyLessInfo : translations.companyMoreInfo"></span>
                                    <span>
                                        <i :class="{'icofont-thin-down': !showMoreInfo.includes(company.id), 'icofont-thin-up': showMoreInfo.includes(company.id) }"></i>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div :class="{
                        'column is-one-quarter has-text-right related-company--selector': true,
                         'is-selected': selectedCompanies.includes(company.id)}"
                    >
                        <button @click="toggleCompany(company.id)"
                                :class="{'button': true, 'is-primary': selectedCompanies.includes(company.id)}"
                        >
                            <span class="icon">
                              <i class="icofont-check-alt"></i>
                            </span>
                            <span v-html="translations.companySelect"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="company-footer" ref="company-footer">
            <div class="field is-grouped">
                <div class="control is-expanded company-footer--label">
                    <label class="label" v-html="selectedCompaniesCount"></label>
                </div>
                <div class="control company-footer--button">
                    <button
                        class="button is-primary"
                        :disabled="selectedCompanies.length <= 0"
                        v-html="translations.introButton"
                        @click="showModal('companyModal')"
                    ></button>
                </div>
            </div>
        </div>

        <div id="modalCompanyRequest" class="modal company-modal" ref="companyModal">
            <div class="modal-background"></div>

            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title company-modal--title">
                        <span class="company-modal--title-icon">
                            <span class="icon">
                                <i class="icofont-file-file"></i>
                            </span>
                        </span>
                        {{ translations.modal.title }}
                    </p>
                    <button class="delete" aria-label="close" @click="hideModal('companyModal')"></button>
                </header>

                <section class="modal-card-body">
                    <p class="company-modal--selected-companies" v-html="selectedCompaniesCount"></p>

                    <div class="columns">
                        <div class="column is-half">
                            <div class="field">
                                <label class="label">{{ translations.modal.fields.first_name }}</label>
                                <div class="control">
                                    <input v-model="request.firstname"
                                           type="text"
                                           :class="{'input': true, 'is-danger': errors.firstname}"
                                           :disabled="submitting"
                                           :placeholder="translations.modal.fields.first_name">
                                </div>
                                <p v-if="errors.firstname" class="help is-danger" v-html="errors.firstname"></p>
                            </div>
                        </div>
                        <div class="column is-half">
                            <div class="field">
                                <label class="label">{{ translations.modal.fields.last_name }}</label>
                                <div class="control">
                                    <input v-model="request.lastname"
                                           type="text"
                                           :class="{'input': true, 'is-danger': errors.lastname}"
                                           :disabled="submitting"
                                           :placeholder="translations.modal.fields.last_name">
                                </div>
                                <p v-if="errors.lastname" class="help is-danger" v-html="errors.lastname"></p>
                            </div>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-half">
                            <div class="field">
                                <label class="label">{{ translations.modal.fields.email }}</label>
                                <div class="control">
                                    <input v-model="request.email"
                                           type="text"
                                           :class="{'input': true, 'is-danger': errors.email}"
                                           :disabled="submitting"
                                           :placeholder="translations.modal.fields.email">
                                </div>
                                <p v-if="errors.email" class="help is-danger" v-html="errors.email"></p>
                            </div>
                        </div>
                        <div class="column is-half">
                            <div class="field">
                                <label class="label">
                                    {{ translations.modal.fields.phone }}
                                    <span class="optional">{{ translations.modal.fields.optional }}</span>
                                </label>
                                <div class="control">
                                    <input v-model="request.phone_number"
                                           type="text"
                                           :class="{'input': true, 'is-danger': errors.phone_number}"
                                           :disabled="submitting"
                                           :placeholder="translations.modal.fields.phone">
                                </div>
                                <p v-if="errors.phone_number" class="help is-danger" v-html="errors.phone_number"></p>
                            </div>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label class="label">{{ translations.modal.fields.address_1 }}</label>
                                <div class="control">
                                    <input v-model="request.address_line_1"
                                           type="text"
                                           :class="{'input': true, 'is-danger': errors.address_line_1}"
                                           :disabled="submitting"
                                           :placeholder="translations.modal.fields.address_1">
                                </div>
                                <p v-if="errors.address_line_1" class="help is-danger" v-html="errors.address_line_1"></p>
                            </div>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label class="label">
                                    {{ translations.modal.fields.address_2 }}
                                    <span class="optional">{{ translations.modal.fields.optional }}</span>
                                </label>
                                <div class="control">
                                    <input v-model="request.address_line_2"
                                           type="text"
                                           :class="{'input': true, 'is-danger': errors.address_line_2}"
                                           :disabled="submitting"
                                           :placeholder="translations.modal.fields.address_2">
                                </div>
                                <p v-if="errors.address_line_2" class="help is-danger" v-html="errors.address_line_2"></p>
                            </div>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-one-third">
                            <div class="field">
                                <label class="label">{{ translations.modal.fields.postcode }}</label>
                                <div class="control">
                                    <input v-model="request.postcode"
                                           type="text"
                                           :class="{'input': true, 'is-danger': errors.postcode}"
                                           :disabled="submitting"
                                           :placeholder="translations.modal.fields.postcode">
                                </div>
                                <p v-if="errors.postcode" class="help is-danger" v-html="errors.postcode"></p>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <label class="label">{{ translations.modal.fields.city }}</label>
                                <div class="control">
                                    <input v-model="request.place"
                                           type="text"
                                           :class="{'input': true, 'is-danger': errors.place}"
                                           :disabled="submitting"
                                           :placeholder="translations.modal.fields.city">
                                </div>
                            </div>
                            <p v-if="errors.place" class="help is-danger" v-html="errors.place"></p>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label class="label">
                                    {{ translations.modal.fields.remarks }}
                                    <span class="optional">{{ translations.modal.fields.optional }}</span>
                                </label>
                                <div class="control">
                                    <textarea v-model="request.remarks"
                                              rows="10"
                                              :class="{'textarea': true, 'is-danger': errors.additional_note}"
                                              :disabled="submitting"
                                              :placeholder="translations.modal.fields.remarks"
                                    ></textarea>
                                </div>
                                <p v-if="errors.additional_note" class="help is-danger" v-html="errors.additional_note"></p>
                            </div>
                        </div>
                    </div>

                    <div v-if="downloadResultUrl !== ''" class="columns company-modal--result">
                        <div class="column">
                            <a :href="downloadResultUrl" target="_blank" class="button is-white">
                                <span class="icon">
                                  <i class="icofont-link"></i>
                                </span>
                                <span>{{ translations.modal.result }}</span>
                            </a>
                        </div>
                    </div>

                    <div class="columns company-modal--submit">
                        <div class="column">
                            <template v-if="errors.form">
                                <p v-for="(error, key) in errors.form"
                                   :key="key"
                                   class="help is-danger company-modal--form-error" v-html="error"></p>
                            </template>
                            <div class="field is-grouped">
                                <div class="control is-expanded">
                                    <label class="checkbox">
                                        <input v-model="request.privacy" :disabled="submitting" type="checkbox" value="1">
                                        <span class="checkmark"></span>
                                        <span v-html="terms"></span>
                                    </label>
                                </div>
                                <div class="control">
                                    <button :class="{'button is-primary is-pulled-right': true, 'is-loading': submitting}"
                                            :disabled="!this.request.privacy"
                                            @click="submitForm"
                                    >
                                        <span>{{ translations.modal.submit }}</span>
                                    </button>
                                </div>
                                <p v-if="errors.privacy" class="help is-danger" v-html="errors.privacy"></p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

        <div id="modalCompanyRequestSuccess" class="modal company-modal" ref="successModal">
            <div class="modal-background"></div>

            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title company-modal--title">{{ translations.successModal.title }}</p>
                </header>

                <section class="modal-card-body company-modal--close">
                    <button class="button is-primary"
                            :aria-label="translations.successModal.close"
                            @click="hideModal('successModal')">{{ translations.successModal.close }}</button>
                </section>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        translations: {
            type: Object,
            default: null
        },
        companies: {
            type: Array,
            required: true
        },
        companyRequestUrl: {
            type: String,
            required: true
        },
        downloadResultUrl: {
            type: String,
            required: true
        },
        token: {
            type: String,
            required: true
        },
        companyRequest: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            selectedCompanies: [],
            showServices: [],
            showMoreInfo: [],
            request: {},
            errors: {},
            submitting: false,
        };
    },
    mounted() {
        this.request = {...this.companyRequest};
    },
    computed: {
        selectedCompaniesCount() {
            const total = this.selectedCompanies.length;
            return total !== 1 ?
                this.translations.introSelectedCompaniesPlural.replace('%selected%', total)
                : this.translations.introSelectedCompanies.replace('%selected%', total);
        },
        terms() {
            return this.translations.modal.privacy_notice.replace('%s', this.companyRequest?.consentLink);
        }
    },
    methods: {
        companyTypes: function (company) {
            return company.companyTypes.join(', ');
        },
        toggleCompany: function (company) {
            const exists = this.selectedCompanies.indexOf(company);
            if (exists === -1) {
                this.selectedCompanies.push(company)
            } else {
                this.selectedCompanies.splice(exists, 1);
            }
        },
        toggleServices: function (company) {
            this.toggleInfo(company)
        },
        toggleInfo: function (company) {
            const exists = this.showMoreInfo.indexOf(company);
            if (exists === -1) {
                this.showMoreInfo.push(company)
                this.$refs['content-wrapper-' + company][0].style.height = this.$refs['content-' + company][0].scrollHeight + 'px';
            } else {
                this.showMoreInfo.splice(exists, 1);
                this.$refs['content-wrapper-' + company][0].style.height = 0;
            }

            const existsServices = this.showServices.indexOf(company);
            if (existsServices === -1) {
                this.showServices.push(company)
            } else {
                this.showServices.splice(existsServices, 1);
            }
        },
        showModal: function (ref) {
            this.$refs[ref].classList.add('is-active');
        },
        hideModal: function (ref) {
            this.$refs[ref].classList.remove('is-active');
        },
        submitForm: function () {
            this.errors = {};

            if (this.request.privacy) {

                this.submitting = true;

                const data = new FormData();
                data.append('company_request_form[firstname]', this.request.firstname ?? '');
                data.append('company_request_form[lastname]', this.request.lastname ?? '');
                data.append('company_request_form[email]', this.request.email ?? '');
                data.append('company_request_form[phone_number]', this.request.phone_number ?? '');
                data.append('company_request_form[address_line_1]', this.request.address_line_1 ?? '');
                data.append('company_request_form[address_line_2]', this.request.address_line_2 ?? '');
                data.append('company_request_form[postcode]', this.request.postcode ?? '');
                data.append('company_request_form[place]', this.request.place ?? '');
                data.append('company_request_form[additional_note]', this.request.remarks ?? '');
                data.append('company_request_form[privacy]', this.request.privacy ?? false);

                // Selected companies
                this.selectedCompanies.forEach(company => {
                    data.append('company_request_form[companies][]', company);
                });

                // Form token
                data.append('company_request_form[_token]', this.token);

                fetch(this.companyRequestUrl, {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                    redirect: 'follow',
                    referrerPolicy: 'no-referrer',
                    body: data
                })
                    .then((response) => response.json())
                    .then(data => {
                        if (data.errors) {
                            this.errors = data.errors;
                            if (this.errors.form) {
                                this.errors.form.unshift(this.translations.form_error);
                            } else {
                                this.errors.form = [this.translations.form_error];
                            }
                        } else if(data.success) {
                            this.hideModal('companyModal');
                            this.showModal('successModal');
                            this.request.privacy = false;
                        } else {
                            this.errors.form = [this.translations.form_error];
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        this.errors.form = [this.translations.form_error];
                    })
                    .finally(() => {
                        this.submitting = false;
                    });
            }
        },
    }
};
</script>
